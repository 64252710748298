import { render, staticRenderFns } from "./CategoryWorkspace.vue?vue&type=template&id=2c30af98&scoped=true"
import script from "./CategoryWorkspace.vue?vue&type=script&lang=ts"
export * from "./CategoryWorkspace.vue?vue&type=script&lang=ts"
import style0 from "./CategoryWorkspace.vue?vue&type=style&index=0&id=2c30af98&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c30af98",
  null
  
)

export default component.exports